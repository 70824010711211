import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { getUserDataWithUsername, IUserProps } from '../common/data/userDummyData';

export interface IAuthContextProps {
	// user: string;
	// setUser?(...args: unknown[]): unknown;
	// userData: Partial<IUserProps>;
	// currentProfile: any;
	// setCurrentProfile?(...args: unknown[]): unknown;
	accessToken: string;
	setAccessToken?(...args: unknown[]): unknown;
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
	children: ReactNode;
}
export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
	const [user, setUser] = useState<string>(localStorage.getItem('facit_authUsername') || '');
	const [userData, setUserData] = useState<Partial<IUserProps>>({});
	const [currentProfile, setCurrentProfile] = useState<any>(
		localStorage.getItem('currentProfile') || {},
	);
	const [accessToken, setAccessToken] = useState<string>(
		localStorage.getItem('accessToken') || '',
	);
	const [messageConfigId, setMessageConfigId] = useState(0);
	// const [auth, setAuth] = useState({});
	// useEffect(() => {
	// 	localStorage.setItem('currentProfile', currentProfile);
	// }, [currentProfile]);
	// useEffect(() => {
	// 	localStorage.setItem('facit_authUsername', user);
	// }, [user]);
	// useEffect(() => {
	// 	localStorage.setItem('accessToken', accessToken);
	// }, [accessToken]);
	// useEffect(() => {
	// 	if (user !== '') {
	// 		setUserData(getUserDataWithUsername(user));
	// 	} else {
	// 		setUserData({});
	// 	}
	// }, [user]);

	const value = useMemo(
		() => ({
			// currentProfile,
			// setCurrentProfile,
			accessToken,
			setAccessToken,
			messageConfigId,
			setMessageConfigId,
			// user,
			// setUser,
			// userData,
		}),
		[user, userData, accessToken, currentProfile, messageConfigId],
	);
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
